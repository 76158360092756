body {
    font-family: Arial, sans-serif;
    background-color: #f2f2f2;
    margin: 0;
    padding: 0;
  }

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  .login-box {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 50px;
    width: 1000%;
    max-width: 400px;
  }

  .login-box h1 {
    text-align: center;
    color:#333333;
    margin-top: 0;
  }

  .input-group {
    margin-bottom: 10px;
  }

  .input-group input[type="text"],
  .input-group input[type="password"],
  .input-group input[type="email"] {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    margin-bottom: 10px;
    outline: 1px solid #aa88dd;
  }

  button {
    width: 100%;
    padding: 10px;
    background-color: #aa88dd;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }

  button:hover {
    background-color: #9977cc;
  }

  .links {
    text-align: center;
    margin-top: 20px;
  }

  .links a {
    color: #666666;
    margin-right: 10px;
    text-decoration: none;
  }

  .message {
    display: none;
    width: 100%;
    background-color: green;
    color: white;
    text-align: center;
    padding: 10px;
    margin-bottom: 20px;
  }

  .failure {
    background-color: red;
  }